import React from 'react';
import SEO from '../components/seo';

import '../components/index.scss';
import Loader from '../components/loader';
import ContextConsumer from '../components/context';
import Button from '../components/button';

const EN = require('../components/copy/EN.json');
const IT = require('../components/copy/IT.json');
let currentLanguage;
let language;

interface Props {}
interface State {
  isLoading: boolean;
}

class SuccessPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  loader() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  renderIndex() {
    return (
      <>
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;

            language === 'IT' ? (currentLanguage = IT) : (currentLanguage = EN);
            return (
              <>
                <SEO title="Bocciardo" />
                <div
                  style={{
                    paddingTop: '15rem',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    color: 'black!important',
                    textAlign: 'center',
                  }}
                >
                  <p>{currentLanguage.Success.text}</p>
                  <Button
                    buttonColor="#26333c"
                    buttonSize="mid"
                    isButton={false}
                    buttonUrl="/"
                  >
                    {currentLanguage.Success.button}
                  </Button>
                </div>
              </>
            );
          }}
        </ContextConsumer>
      </>
    );
  }

  render() {
    return <>{this.renderIndex()}</>;
  }
}

export default SuccessPage;
